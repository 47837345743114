import 'bootstrap';
import './scss/app.scss';
import './scss/main.scss';
import './scss/main-responsive.scss';

import * as jQuery from 'jquery';
window['jQuery'] = jQuery;
var $ = require('jquery');
require('blast-text');
import Swiper from 'swiper';
require('jquery.easing');

//Load single div backgrounds
$(function () {
    var image = document.querySelectorAll('img[data-bg="true"]');

    for (var i = 0; i < image.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = image[n].src;

            function imageFound() {
                image[n].parentNode.style.backgroundImage = 'url(' + img.src + ')';
            }

            function imageNotFound() {
                image[n].parentNode.style.backgroundColor = '#f5f3ef';
            }
        }(i));
    }
});

//Load section backgrounds
$(function () {
    var section = document.querySelectorAll('div[data-section-bg], section[data-section-bg]');

    for (var i = 0; i < section.length; i++) {
        (function (n) {
            var img = new Image();

            img.onload = imageFound;
            img.onerror = imageNotFound;
            img.src = section[n].getAttribute('data-section-bg');

            function imageFound() {
                section[n].style.backgroundImage = 'url(' + img.src + ')';
            }

            function imageNotFound() {
                section.style.backgroundColor = '#f5f3ef';
                section.style.opacity = '1';
            }
        }(i));
    }
});

//Swiper testimonials
$(function() {
    var swiperWrapper = $('.swiper-testimonials-wrapper');

    if (swiperWrapper.length > 0) {
        var swiper = new Swiper('.swiper-testimonials', {
            slidesPerView: '1',
            noSwipingClass: 'swiper-testimonials',
            autoplay: {
                delay: 4000,
            },
            navigation: {
                prevEl: '.swiper-testimonials-wrapper .swiper-btn-prev',
                nextEl: '.swiper-testimonials-wrapper .swiper-btn-next',
            }
        });
    }
});

//Fake hover effect
$(function ($) {
    $('.navbar-nav .nav-link, .btn:not(.btn-no-effect)').each(function () {
        const $this = $(this);

        $this.attr('data-text', $this.text());
        $this.text('');

        let $originalHover = $('<div class="original-hover"></div>').appendTo($this);
        let $fakeHover = $('<div class="fake-hover"></div>').appendTo($this);

        $originalHover.html($this.data('text'));
        $fakeHover.html($this.data('text'));

        $originalHover.blast({
            delimiter: 'character',
            tag: 'div'
        });

        $fakeHover.blast({
            delimiter: 'character',
            tag: 'div'
        });

        $fakeHover.each(function () {
            const $this = $(this);

            $($this.find('div').get().reverse()).each(function (i) {
                const $this = $(this);

                $this.css({
                    'transition-delay': i * 20 + 'ms'
                });
            });
        });

        $originalHover.each(function () {
            const $this = $(this);

            $($this.find('div').get().reverse()).each(function (i) {
                const $this = $(this);

                $this.css({
                    'transition-delay': i * 20 + 'ms'
                });
            });
        });
    });
}(jQuery));

//Smooth scroll
$(function () {
    // Select all links with hashes
    $('a[data-scroll="smooth"], [data-scroll="smooth"]').click(function (event) {
        var thatTarget = $(this.hash),
            a;

        var timeout = 750;

        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, timeout, 'easeInOutQuart');
            }
        }
    });
});

//Members
$(function () {
    var trigger = document.querySelectorAll('.member-block-header'),
        active;

    var firstLoad = false;

    for (var i = 0; i < trigger.length; i++) {
        if (trigger[i].classList.contains('active')) {
            active = trigger[i];
            firstLoad = true;

            toggleNavigation();
        }

        trigger[i].onclick = toggleNavigation;
    }

    function toggleNavigation(e) {
        var that = firstLoad ? active : this,
            target = firstLoad ? active.nextElementSibling : that.nextElementSibling;

        $(that).parent().siblings().find($(trigger)).removeClass('active');
        $(that).parent().siblings().find('.member-block-body').css({
            maxHeight: '0'
        });

        if (firstLoad) {
            that.classList.add('active');
            target.style.maxHeight = target.scrollHeight + 'px';
        } else {
            e.preventDefault();
            if (!that.classList.contains('active')) {
                that.classList.add('active');
                target.style.maxHeight = target.scrollHeight + 'px';
            } else {
                that.classList.remove('active');
                target.style.maxHeight = '0' + 'px';
            }
        }

        firstLoad = false;
    }
});

function initGoogleMaps() {
    function initialize() {
        var map_canvas = document.getElementById('googleMap');

        var map_options = {
            center: new google.maps.LatLng(40.666530, -73.946870),
            zoom: 10,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            draggable: true
        };

        var image = 'img/map-pin.png?v=1.1';
        var map = new google.maps.Map(map_canvas, map_options);
        var locations = [
            ['<strong>Emergent Consulting</strong><br/>36 Hayward Road<br/> Ferntree Gully, VIC, 3156', 40.666530, -73.946870, 1]
        ];

        var infowindow = new google.maps.InfoWindow();

        var marker, i;

        for (i = 0; i < locations.length; i++) {
            marker = new google.maps.Marker({
                position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                map: map,
                icon: image
            });

            google.maps.event.addListener(marker, 'click', (function (marker, i) {
                return function () {
                    infowindow.setContent(locations[i][0]);
                    infowindow.open(map, marker);
                }
            })(marker, i));
        }

        var styles = [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dadada"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ];
        map.setOptions({styles: styles});
    }

    function loadScript(src, callback) {
        //Unload gogole maps api
        if (window.google !== undefined && google.maps !== undefined) {
            delete google.maps;
            $('script').each(function () {
                if (this.src.indexOf('googleapis.com/maps') >= 0
                    || this.src.indexOf('maps.gstatic.com') >= 0
                    || this.src.indexOf('earthbuilder.googleapis.com') >= 0) {
                    $(this).remove();
                }
            });
        }

        //Load google maps api
        var script = document.createElement("script");
        script.type = "text/javascript";
        if (callback) script.onload = callback;
        document.getElementsByTagName("head")[0].appendChild(script);
        script.src = src;
    }

    if ($('#googleMap').length > 0) {
        loadScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDYCsNb-USQrIoIOvDAAzkITEzuImujR14', initialize);
    }
}

//Google Maps
$(function () {
    initGoogleMaps();
});